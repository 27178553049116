body {
    background-color: lightgray;
}

div.form-group span.invalid-feedback
{
    float: left;
}

span.badge-danger
{
    text-shadow: none;
    background-color: red;
    font-size: 8px;
}
span.form-error-message
{
    font-size: 10px;
}

div.selectize-input{
    padding: 5px;
    float: left;
    display:inline-block;
}

div.nav-selection-input{
    width:260px;
    float: left;
    display:inline-block;
    padding-right:10px;
}

div.nav-count
{
    font-size: 25px;
    line-height: 47px;
    font-weight: 600;
    font-size:1.7vw;
}

/* Style the tab */
.tab {
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
    background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
    background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
    display: none;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-top: none;
}

.my-switchery {
    background-color: #fff;
    border: 1px solid #dfdfdf;
    border-radius: 20px;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    position: relative;
    vertical-align: middle;
    width: 50px;
}

.my-switchery > small {
    background: #fff;
    border-radius: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
    height: 25px;
    position: absolute;
    top: 0;
    width: 25px;
}

.cross-promo
{
    display:block;
    height:100%;
}

.cross-promo label{
    display:block;
}

.cross-promo input{
    float:left;
    margin-right: 5px;
}